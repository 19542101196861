<template>
  <div class="stationboard">
    <h1>Stationboard</h1>
    <div class="dropdown">
      <select id="dropdown">
        <option value="bern" @click="getTimetableBern">Bern</option>
        <option value="chur" @click="getTimetableChur">Chur</option>
        <option value="zuerich" @click="getTimetableZuerich">Zürich</option>
      </select>
    </div>
    <table class="tableHead">
      <tr>
        <td>Destination</td>
        <td>Abfahrtszeit</td>
        <td>Zugtyp</td>
        <td>Gleis</td>
      </tr>
    </table>
  </div>
  <SingleTrain :tableContent="tableContent" />
</template>

<script>
// Komponente importieren
import SingleTrain from '@/components/SingleTrain.vue'

export default {
  name: 'stationBoardView',
  components: {
    SingleTrain
  },

  data() {
    return {
      tableContent: {}
    }
  },

  //Die drei verschiedenen fetch-Funktionen definieren
  methods: {
    getTimetableBern() {
      fetch("https://transport.opendata.ch/v1/stationboard?station=bern")
        .then(res => res.json())
        .then(data => {
          //Die erhaltenen daten mittels Methode in einem Objekt speichern
          this.fillTableContent(data)
        })
    },

    getTimetableChur() {
      fetch("https://transport.opendata.ch/v1/stationboard?station=chur")
        .then(res => res.json())
        .then(data => {
          //Die erhaltenen daten mittels Methode in einem Objekt speichern
          this.fillTableContent(data)
        })
    },

    getTimetableZuerich() {
      fetch("https://transport.opendata.ch/v1/stationboard?station=zuerich")
        .then(res => res.json())
        .then(data => {
          //Die erhaltenen daten mittels Methode in einem Objekt speichern
          this.fillTableContent(data)
        })
    },

    //Die 10 ersten Einträge durch iterieren und jeweils destination, abfahrt, Zugtyp und Gleis in einem eigenen Key - Value - pair speichern
    fillTableContent(data) {
      for (let i = 0; i < 10; i++) {
        this.tableContent[i] = {
          destination: data.stationboard[i].to,
          departure: data.stationboard[i].stop.departure,
          trainType: data.stationboard[i].category,
          platform: data.stationboard[0].stop.platform
        }
      }
    }
  },

  mounted() {
    //Fetchfunktion Aufruf beim erstmaligen laden der Webseite: Standardmässig Bern
    this.getTimetableBern()
  }
}
</script>

<style>
h1 {
  background-color: black;
  color: white;
  height: 100px;
  display: flex;
  align-items: center;
  margin: auto;

}

.tablehead {
  width: 100%
}

tr {
  width: 100%
}

td{
  width: 25%
}
</style>
