<template>
  <table class="tableContent">
    <tr v-for="content in tableContent">
      <!-- Die Inhalte der vom Dropdown abhängigen tableContent in einer Tabelle darstellen -->
      <td>{{content.destination}}</td>
      <td>{{content.departure}}</td>
      <td>{{content.trainType}}</td>
      <td>{{content.platform}}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'singleTrain',
  props: ['tableContent']
}
</script>

<style scoped>

</style>
